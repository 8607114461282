import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';

var bgimage = require('./../../images/background/bg-site.png');

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = { logo: require('./../../images/bnlogotans.png') };
    }

    updateFooterLogo = (updatedlogo) => { this.currentstate.logo = updatedlogo; }

    render() {
        return (
            <>
                <footer className="site-footer footer-large footer-dark footer-wide">
                    <div className="container call-to-action-wrap bg-no-repeat bg-center" style={{ backgroundImage: 'url(' + bgimage + ')' }}>
                        <div className="p-a20 bg-primary">
                            <div className="row text-center">
                                <div className="col-md-12 col-sm-12">
                                    <div className="call-to-action-left text-black">
                                        <h4 className="text-uppercase m-b10 m-t0 ">Bhagirath Narayan Constructions Pvt. Ltd.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* FOOTER BLOCKS START */}
                    <div className="footer-top overlay-wraper">
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        <div className="logo-footer clearfix p-b15">
                                            <NavLink to={"./"}><img src={this.currentstate.logo} alt="Company Logo" /></NavLink>
                                        </div>
                                        <p className="max-w400">
                                            With over a decade of expertise, BNC has evolved into a trusted leader in the construction industry, known for delivering exceptional projects and building legacies that endure through quality and innovation.              </p>
                                        <ul className="social-icons mt-social-links">
                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                        </ul>
                                    </div>
                                </div>

                                {/* QUICK LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_services">
                                        <h4 className="widget-title">Quick Links</h4>
                                        <ul>
                                            <li><NavLink to={"/about"}>About Us</NavLink></li>
                                            <li><NavLink to={"/products"}>Our Products</NavLink></li>
                                            <li><NavLink to={"/projects"}>Projects</NavLink></li>
                                            <li><NavLink to={"/enquiry"}>Enquiry</NavLink></li>
                                        </ul>
                                    </div>
                                </div>

                                {/* CONTACT INFO */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">Visit Us</h4>
                                        <ul className="widget_address">
                                            <li><i className="fa fa-map-marker"></i>#3,4, BIZHUB, Near Ambedkar Chowk, Pimpri, Pune -411018</li>
                                            <li><i className="fa fa-phone"></i> +91 8263-835-149</li>
                                            <li><i className="fa fa-envelope"></i> info@bnconstructions.com</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* ENQUIRY FORM */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <h4 className="widget-title">Get In Touch</h4>
                                    <form className="cons-contact-form2 form-transparent" method="post" action="">
                                            <div className="input input-animate">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" name="username" id="name" required />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" name="email" id="email" required />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <label htmlFor="Phone">Phone</label>
                                                <input type="text" name="phone" id="Phone" required />
                                                <span className="spin" />
                                            </div>
                                            
                                            <div className="text-center p-t10">
                                                <button type="submit" className="site-button btn-effect ">
                                                    Submit Now
                    </button>
                                            </div>
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <span className="copyrights-text">© 2024 Multicolor Steels Pvt. Ltd.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
            </>
        );
    }
}

export default Footer;
